import React from 'react'
import BlogCards from './blog/blog'

export default function BlogPage() {
  return (
    <div>
      <BlogCards />
    </div>
  )
}
